import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-recruitment-login',
  templateUrl: './recruitment-login.component.html',
  styleUrls: ['./recruitment-login.component.scss']
})
export class RecruitmentLoginComponent implements OnInit, AfterViewInit {
  public entity:any;

  constructor() { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.entity={
      email: null,
      password: null,
    }
  }

  public login(): void{

  }
}
