import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordService } from '../../@shared/services/reset-password.service';
import { MustMatch } from '../../@shared/validators/must-match.validators'

@Component({
  selector: 'ngx-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  public entity: any;
  resetPasswordForm: FormGroup;
  resetPasswordFormSubmitted: boolean;
  resetToken: string;

  constructor(
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public resetPasswordService: ResetPasswordService,
    private router: Router,) {

    this.resetToken = this.activatedRoute.snapshot.paramMap.get('token') || '';
    if (!this.resetToken) {
      window.location.href = '/attendance/login';
    } else {
      this.resetPasswordForm = this.fb.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirm_password: ['', Validators.required]
      }, {

        validator: MustMatch('password', 'confirm_password'),
      });
    }

  }

  ngOnInit(): void {
  }

  get rpf() {
    debugger
    return this.resetPasswordForm.controls;
  }

  validateAllFormFields(formGroup: FormGroup) {         // {1}
    Object.keys(formGroup.controls).forEach(field => {  // {2}
      const control = formGroup.get(field);             // {3}
      if (control instanceof FormControl) {             // {4}
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {        // {5}
        this.validateAllFormFields(control);            // {6}
      }
    });
  }
  passwordResetFormOnSubmit() {
    debugger
    const password = this.resetPasswordForm.get('password').value;
    const body = {
      password: password
    }
    if (this.resetPasswordForm.valid) {
      this.resetPasswordService.resetPassword(this.resetToken, body).subscribe(obj => {
        // @ts-ignore
        if (obj) {
          setTimeout(() => {
            this.router.navigate(['/attendance/login']);
          }, 3000);
        }
        this.resetPasswordFormSubmitted = false;

      }, error => {

        this.resetPasswordFormSubmitted = false;
      });
    } else {
      this.resetPasswordFormSubmitted = false;
      this.validateAllFormFields(this.resetPasswordForm);
      return false;
    }
  }
}
