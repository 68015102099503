import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FullCalendarModule } from "@fullcalendar/angular";
import { NbActionsModule, NbBadgeModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbRadioModule, NbSelectModule, NbToggleModule, NbUserModule } from "@nebular/theme";
import { QRCodeModule } from "angularx-qrcode";
import { HighchartsChartModule } from "highcharts-angular";
import { ThemeModule } from "./@theme/theme.module";


export const NBIMPORTS = [
    CommonModule,
    FullCalendarModule,
    HighchartsChartModule,
    ThemeModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    QRCodeModule,
    NbToggleModule,
    NbBadgeModule,
    FormsModule,
    ReactiveFormsModule,
];
