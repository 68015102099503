import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { AbstractService } from "./common/abstract.service";
import { ServiceErrorHandler } from "../models/service-error-handler-decorator";

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService extends AbstractService {

  constructor(private http:HttpClient,) {
    super();
  }

  @ServiceErrorHandler()
  public sendEmail(body): Observable<any> {
    debugger
    return this.http.post<any>(`/api/password/sendResetPasswordLink`, body, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }


  @ServiceErrorHandler()
  public resetPassword(token: any, body): Observable<any> {
    debugger
    return this.http.post<any>(`/api/password/reset/${token}`, body, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }
}
