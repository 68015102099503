import * as moment from "moment";

//check in and check out payload map
export function statisticsDateMapping(data){
   data.check_in=moment(data.check_in).format("YYYY-MM-DD HH:mm:ss");
   data.check_out=moment(data.check_out).format("YYYY-MM-DD HH:mm:ss");
   return data;
}
export function statisticsDateMappingLeaves(data){
   data.date_from=moment(data.date_from).format("YYYY-MM-DD HH:mm:ss");
   data.date_to=moment(data.date_to).format("YYYY-MM-DD HH:mm:ss");
   return data;
}
export function statisticsMinDayMapping(data){
      let date=moment(data).format("YYYY-MM-DD");
      date=moment(date).add(0, 'hours').format("YYYY-MM-DD HH:mm:ss");
      return date
}
export function statisticsMaxDayMapping(data){
   let date=moment(data).format("YYYY-MM-DD");
   date=moment(date).add(23, 'hours').format("YYYY-MM-DD HH:mm:ss");
   return date
}
//modal type map
export function modalEntityType(event,type){
   switch(type){
      case 'attendance':
         switch(event.type){
            case 'create':
               return {user: event.user.email,check_in:event.eventDate,check_out:event.eventDate,status: 'present',}
            case 'edit':
               return {user: event.user.email,check_in:event.eventDate.start,check_out:event.eventDate.end,status: 'present',}
         }
         break;
      case 'leaves':
         switch(event.type){
            case 'create':
               return {user: event.user.id,date_from:event.eventDate,date_to:event.eventDate,status:'pending',description:'',type:'option',duration:'option'}
            case 'edit':
               return {user: event.user.id,date_from:event.eventDate.start,date_to:event.eventDate.end,status:'pending',description:event.extendedProps.description ? event.extendedProps.description : '',type:event.extendedProps.text,duration:event.extendedProps.durationType}
         }
         break;
      default:
         return {};
   }

}