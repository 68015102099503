<ng-container>
  <h1 id="title" class="title">Reset Password</h1>
  <p class="sub-title">
    Please choose your new password and confirm it in the form below.
  </p>
  <form
    novalidate
    aria-labelledby="title"
    class="ng-untouched ng-pristine"
    [formGroup]="resetPasswordForm"
  >
    <div class="form-control-group">
      <span class="label-with-link">
        <label for="input-password" class="label">Password:</label>
      </span>
      <input
        nbInput
        fullWidth
        name="password"
        type="password"
        id="input-password"
        fieldsize="large"
        [ngClass]="{
          'is-invalid':
            (rpf.password.dirty || resetPasswordFormSubmitted) &&
            rpf.password.errors
        }"
        class="size-large ng-untouched ng-pristine ng-invalid nb-transition"
        required=""
        minlength="4"
        maxlength="50"
        formControlName="password" placeholder="New Password"
      />
      <div
        *ngIf="
          (rpf.password.dirty || resetPasswordFormSubmitted) &&
          rpf.password.errors
        "
        class="invalid-feedback"
      >
        <div *ngIf="rpf.password.errors.required">Password is required</div>
        <div *ngIf="rpf.password.errors.minlength">
          Password should not be less than 8 characters
        </div>
        <div *ngIf="rpf.password.errors.mustMatch">Password is invalid!</div>
      </div>
    </div>

    <div class="form-control-group">
      <span class="label-with-link">
        <label for="input-password" class="label">Confirm Password:</label>
      </span>
      <input
        nbInput
        fullWidth
        [ngClass]="{
          'is-invalid':
            (rpf.confirm_password.dirty || resetPasswordFormSubmitted) &&
            rpf.confirm_password.errors
        }"
        name="confirm_password"
        type="password"
        id="input-password"
        fieldsize="large"
        class="size-large ng-untouched ng-pristine ng-invalid nb-transition"
        minlength="4"
        maxlength="50"
        formControlName="confirm_password" placeholder="Confirm Password"
      />
      <div
        *ngIf="
          (rpf.confirm_password.dirty || resetPasswordFormSubmitted) &&
          rpf.confirm_password.errors
        "
        class="invalid-feedback"
      >
        <div *ngIf="rpf.confirm_password.errors.required">
          Confirm Password is required
        </div>
        <div *ngIf="rpf.confirm_password.errors.mustMatch">
          Confirm Password should match the Password
        </div>
      </div>
    </div>
    <!-- <pre>
      Form Error - {{ resetPasswordForm.errors | json }}
    </pre> -->
    <button
      nbbutton
      fullwidth
      status="primary"
      size="large"
      [disabled]="resetPasswordForm.invalid"
      class="appearance-filled full-width size-large shape-rectangle status-primary"
      (click)="passwordResetFormOnSubmit()"
    >
      Submit
    </button>
  </form>
</ng-container>
