import { NewPasswordComponent } from './new-password/new-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { AuthGuard } from '../@core/guard/auth.guard';
import { AttendanceLoginComponent } from './attendance-login/attendance-login.component';
import { ResetPasswordComponent } from './attendance-login/reset-password/reset-password.component';
import { AuthComponent } from './auth.component';
import { RecruitmentLoginComponent } from './recruitment-login/recruitment-login.component';

const routes: Routes = [{
  path: '',
  component: NbAuthComponent,
  children: [
    {
      path: 'attendance/login',
      component: AttendanceLoginComponent,
    },
    {
      path: 'attendance/resetPassword/:token',
      component: NewPasswordComponent,
    },
    {
      path: 'recruitment/login',
      component: RecruitmentLoginComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
