import { Component } from '@angular/core';


@Component({
  selector: 'ngx-pages',
  styleUrls: ['auth.component.scss'],
  template: `
    <router-outlet></router-outlet>
    `,
})
export class AuthComponent {

}
