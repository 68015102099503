<div mat-dialog-title>
  <div class="row">
    <div class="col-md-10">
      <h6>Attendance or Leaves</h6>
    </div>
    <div class="col-md-2">
      <nb-icon
        mat-dialog-close
        class="pull-right close-icon"
        icon="close"
      ></nb-icon>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <label class="label">Employee Name</label>
      <input
        type="text"
        nbInput
        fullWidth
        placeholder="Name"
        [disabled]="true"
        name="name"
        [(ngModel)]="data.user.name"
      />
    </div>
    <div class="col-md-12 mt-10">
      <label class="label">Date</label>
      <input
        [owlDateTimeTrigger]="dt1"
        [owlDateTime]="dt1"
        nbInput
        fullWidth
        placeholder="Date"
        name="date"
        [disabled]="true"
        [(ngModel)]="data.date"
      />
      <owl-date-time
        [pickerType]="'calendar'"
        #dt1
        [disabled]="true"
      ></owl-date-time>
    </div>
    <div class="col-md-12 mt-10">
      <label class="label">Select Type</label>
      <nb-select
        fullWidth
        [(ngModel)]="type"
        (selectedChange)="onTypeSelection()"
        [disabled]="data.entityDisable"
      >
        <nb-option value="option">Choose an option</nb-option>
        <nb-option value="attendance">Attendance</nb-option>
        <nb-option value="leaves">Leaves</nb-option>
      </nb-select>
    </div>
  </div>

  <!-- Attendance -->
  <ng-container *ngIf="type == 'attendance'">
    <div class="row mt-20">
      <div class="col-md-12">
        <label class="label">Check In</label>
        <input
          [owlDateTimeTrigger]="dt2"
          [owlDateTime]="dt2"
          nbInput
          fullWidth
          placeholder="Check In"
          name="check in"
          [(ngModel)]="attendance.check_in"
          [min]="onCurrentMinDaySet(attendance.check_in)"
          [max]="onCurrentMaxDaySet(attendance.check_in)"
        />
        <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
      </div>
      <div class="col-md-12 mt-10">
        <label class="label">Check Out</label>
        <input
          [owlDateTimeTrigger]="dt3"
          [owlDateTime]="dt3"
          nbInput
          fullWidth
          placeholder="Check Out"
          name="check out"
          [(ngModel)]="attendance.check_out"
          [min]="onCurrentMinDaySet(attendance.check_out)"
          [max]="onCurrentMaxDaySet(attendance.check_out)"
        />
        <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
      </div>
    </div>
  </ng-container>
  <!-- Leaves -->
  <ng-container *ngIf="type == 'leaves'">
    <div class="row mt-20">
      <!-- Duration -->
      <div class="col-md-12">
        <label class="label">Duration*</label>
        <nb-select fullWidth [(ngModel)]="attendance.duration" required>
          <nb-option value="option">Choose an option</nb-option>
          <nb-option value="short">Short Leave</nb-option>
          <nb-option value="half">Half Leave</nb-option>
          <nb-option value="full">Full Leave</nb-option>
        </nb-select>
      </div>
      <!-- Type -->
      <div class="col-md-12">
        <label class="label">Leave Type*</label>
        <nb-select
          [disabled]="
            type == 'leaves' ? attendance.duration == 'option' : false
          "
          fullWidth
          [(ngModel)]="attendance.type"
          (selectedChange)="onLeaveSelection()"
          required
        >
          <nb-option value="option">Choose an option</nb-option>
          <nb-option value="{{leaves.value}}" *ngFor="let leaves of leaveTypes">{{leaves.name }}</nb-option>
          <!-- <nb-option value="casual">Casual</nb-option>
          <nb-option value="sick">Sick</nb-option>
          <nb-option value="unpaid">Un Paid</nb-option> -->
        </nb-select>
       
      </div>
      <div class="col-md-12 mt-10">
        <div *ngIf="!isLeaveremaining" class="text-center">
            <span class="text-danger">{{ remainingErrorMsg }}</span>
          </div>
      </div>
      

      <div class="col-md-12 mt-10">
        <label class="label">From</label>
        <input
          [disabled]="!proceedLeave"
          [owlDateTimeTrigger]="dt4"
          [owlDateTime]="dt4"
          nbInput
          fullWidth
          placeholder="Time Duration"
          [(ngModel)]="attendance.date_from"
          name="from"
        />
        <owl-date-time #dt4></owl-date-time>
      </div>
      <div class="col-md-12 mt-10">
        <label class="label">To</label>
        <input
          [disabled]="!proceedLeave"
          [owlDateTimeTrigger]="dt5"
          [owlDateTime]="dt5"
          nbInput
          fullWidth
          placeholder="Time Duration"
          [(ngModel)]="attendance.date_to"
          name="to"
        />
        <owl-date-time #dt5></owl-date-time>
      </div>
      <div class="col-md-12 mt-10">
        <label class="label">Reason</label>
        <textarea
          [disabled]="!proceedLeave"
          fullWidth
          nbInput
          placeholder="Message"
          [(ngModel)]="attendance.description"
          name="description"
        ></textarea>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="data.type == 'edit'"
    (click)="onDelete(type)"
    size="small"
    nbButton
    status="danger"
    class="employee-btn mr-10"
  >
    Delete
  </button>
  <button size="small" mat-dialog-close nbButton>Cancel</button>
  <button
    size="small"
    *ngIf="data.type == 'create'"
    nbButton
    status="primary"
    class="employee-btn ml-10"
    type="submit"
    [disabled]="
      type == 'leaves' ? attendance.type == 'option' || !proceedLeave : false
    "
    (click)="onSubmit(type)"
  >
    Save
  </button>
  <button
    size="small"
    *ngIf="data.type == 'edit'"
    nbButton
    status="primary"
    class="employee-btn ml-10"
    type="submit"
    [disabled]="type == 'leaves' ? attendance.type == 'option' : false"
    (click)="onUpdate(type)"
  >
    Update
  </button>
</mat-dialog-actions>
