import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Globals } from "../../gloabls";
import { CriteriaAdapter } from "../models/criteria.model";
import { ServiceErrorHandler } from "../models/service-error-handler-decorator";
import { User } from "../models/statistics/statistics.model";
import { AbstractService } from "./common/abstract.service";

@Injectable({
  providedIn: "root",
})
export class StaticticsService extends AbstractService {
  constructor(
    private http: HttpClient,
    private adapter: CriteriaAdapter,
    
  ) {
    super();
  }
  //get all users
  @ServiceErrorHandler()
  public getUsers(): Observable<User> {
    return this.http
      .get<User>(`/api/admin/users?count=1000`, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res.success) {
            return res.data.data;
          }
        }),
        shareReplay()
      );
  }
  //mark attendance
  @ServiceErrorHandler()
  public markAttendance(attendance, id): Observable<any> {
    return this.http
      .post(
        `/api/admin/attendance/users/${id}/mark`,
        attendance,
        this.httpOptions
      )
      .pipe(shareReplay());
  }
  //mark leaves
  @ServiceErrorHandler()
  public markLeave(leave): Observable<any> {
    return this.http
      .post(`/api/admin/leaves`, leave, this.httpOptions)
      .pipe(shareReplay());
  }
  //update attendance
  @ServiceErrorHandler()
  public updateAttendance(attendance, id): Observable<any> {
    return this.http
      .put(`/api/admin/attendance/${id}`, attendance, this.httpOptions)
      .pipe(shareReplay());
  }
  //update leaves
  @ServiceErrorHandler()
  public updateLeave(attendance, id): Observable<any> {
    return this.http
      .put(`/api/admin/leaves/${id}`, attendance, this.httpOptions)
      .pipe(shareReplay());
  }
  //delete attendance
  @ServiceErrorHandler()
  public deleteAttendance(id): Observable<any> {
    return this.http
      .delete(`/api/admin/attendance/${id}`, this.httpOptions)
      .pipe(shareReplay());
  }
  //delete leaves
  @ServiceErrorHandler()
  public deleteLeaves(id): Observable<any> {
    return this.http
      .delete(`/api/admin/leaves/${id}`, this.httpOptions)
      .pipe(shareReplay());
  }

  @ServiceErrorHandler()
  public getLeaveQuota(userid): Observable<any> {
    return this.http.get(`/api/admin/leaves/users/quota?user_id=${userid}`, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data[0];
        }
      }),
      shareReplay()
    );
  }


  @ServiceErrorHandler()
  public getLeaveTypes(): Observable<any> {
    return this.http.get(`/api/admin/leave-types`, this.httpOptions).pipe(
      map((res: any) => {
        if (res.success) {
          return res.data;
        }
      }),
      shareReplay()
    );
  }
}
